import React from 'react'
import bg23 from './bg23.png';
// import emailcap from "../../assets/svg/Emailcapture-rafiki1.svg";
import './section23.css';

export default function Section23() {
  return (
    <div className="one-section one-section-23" style={{ backgroundImage: `url(${bg23})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="fw400 lh-4 text-center max-w860 mx-auto text-white fs-42">
              <span className="fw700"></span>Impressed? <span className="fw700 text-yellow">Many-Many More Feature</span> Are There To Amaze You.
            </div>
            <div className="fw400 text-center text-white margint10 max-w550 mx-auto fs-22 mb-4">
              <span className="text-center fw400">So, what else do you need for your <span className="fw600 text-yellow">business</span> to go shoulder-to-shoulder with your <span className="fw600 text-yellow">competitors</span> ?</span>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}