import React from "react";
import bg41 from "./bg41.png";
// import toppng33 from "./top.svg";
// import check33 from "./check.png";
// import mamico from "../../assets/svg/Messaging-amico.svg";

import "./section41.css";
export default function Section41() {
  return (
    <div id="main" className="one-section one-section-41" style={{ backgroundImage: `url(${bg41})` }}>
      <div className="container">
        <div className="col-12 text-center ">
          <h1 className="fs-42 fw500 lh-4 text-yellow"><span className="fw700"></span>FAQs</h1>
          <h1 className="fs-42 fw500 lh-4 text-white">Frequently Asked Questions</h1>
          <p className="fs mx-auto fs-22 fw400 lh-5 gray4 margint10 max-w800">
            Have questions, we’re here to help </p>
        </div>

        <div className="accordion my-own-cont mt-5" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is Mumara One?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Mumara One is an email marketing automation system that enables businesses and marketers to easily design and send emails to the audience. Our easy-to-use interface is tremendously helpful for the users, and intelligent infrastructure sophisticatedly handles the transactional processes so you don’t have to put much effort into nurturing the leads. You can use ready-made landing pages and web forms to use instantly boost your sales and mailing list.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Will My Sales Hike Due to an Email Marketing Campaign?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Email marketing is a tested channel that can surely hike sales. It inspires the audience in the one-on-one communication process – compelling them to invest money in your brand. It gives you a tremendous return on investment of $42 for every $1 spent. Mumara One gives you an all-in-one solution for your marketing automation obstructions the way you idealize.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Can I Change My Plan on Mumara One?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes, you can change/upgrade your current plan according to your requirements, whenever you want. Simply navigate to Subscription and change your plan under Mumara One Pricing Plan.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                How Do I Grow My Email Subscriber List?
              </button>
            </h2>
            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                We offer a pre-made subscription form ready to use to grow your healthy email list. We don’t believe in buying mailing lists because most of them do not subscribe to receive emails from you. Select a beautifully designed subscription/web form to get more interested users.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                Do I Need an SMTP to Send Emails?
              </button>
            </h2>
            <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes, you need at least one SMTP account or an integrated ESP account to send your emails.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingsix">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                Can I Cancel My Subscription?
              </button>
            </h2>
            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes, you can cancel your subscription at any time before your next billing date.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingsev">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesev" aria-expanded="false" aria-controls="collapsesev">
                Can I Track User Activity on My Website?
              </button>
            </h2>
            <div id="collapsesev" className="accordion-collapse collapse" aria-labelledby="headingsev" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Mumara One has made it easy for you to track the movement of your user on your website through tracking pixels. Track the visitor’s movement on the pages he visits and the abandoned shipping carts. After tracking the movement, you can send emails to narrow down the checkout process.
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
