import React from 'react';
import Section1 from './Sections/Section1/Section1';
import Section2 from './Sections/Section2/Section2';
import Section3 from './Sections/Section3/Section3';
import Section4 from './Sections/Section4/Section4';
import Section5 from './Sections/Section5/Section5';
import Section6 from "./Sections/Section6/Section6";
import Section7 from "./Sections/Section7/Section7";
import Section8 from "./Sections/Section8/Section8";
import Section9 from "./Sections/Section9/Section9";
import Section10 from "./Sections/Section10/Section10";
import Section11 from "./Sections/Section11/Section11";
import Section12 from "./Sections/Section12/Section12";
import Section13 from './Sections/Section13/Section13';
import Section14 from "./Sections/Section14/Section14";
import Section15 from "./Sections/Section15/Section15";
import Section16 from './Sections/Section16/Section16';
import Section17 from './Sections/Section17/Section17';
import Section18 from './Sections/Section18/Section18';
import Section19 from './Sections/Section19/Section19';
import Section20 from './Sections/Section20/Section20';
import Section21 from "./Sections/Section21/Section21";
import Section22 from "./Sections/Section22/Section22";
import Section23 from './Sections/Section23/Section23';
import Section24 from './Sections/Section24/Section24';
import Section25 from "./Sections/Section25/Section25";
import Section26 from './Sections/Section26/Section26';
import Section27 from './Sections/Section27/Section27';
import Section28 from './Sections/Section28/Section28';
import Section29 from './Sections/Section29/Section29';
import Section30 from './Sections/Section30/Section30';
import Section31 from "./Sections/Section31/Section31";
import Section32 from './Sections/Section32/Section32';
import Section33 from './Sections/Section33/Section33';
import Section34 from './Sections/Section34/Section34';
import Section35 from './Sections/Section35/Section35';
import Section36 from './Sections/Section36/Section36';
import Section37 from './Sections/Section37/Section37';
import Section38 from './Sections/Section38/Section38';
import Section39 from './Sections/Section39/Section39';
import Section40 from './Sections/Section40/Section40';
import Section41 from "./Sections/Section41/Section41";

import "./fonts/font.css";
import "./main.css";



function App() {
  return (
    <div className="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13 />
      <Section14 />
      <Section15 />
      <Section16 />
      <Section17 />
      <Section18 />
      <Section19 />
      <Section20 />
      <Section21 />
      <Section22 />
      <Section23 />
      <Section24 />
      <Section25 />
      <Section26 />
      <Section27 />
      <Section28 />
      <Section29 />
      <Section30 />
      <Section31 />
      <Section32 />
      <Section33 />
      <Section34 />
      <Section35 />
      <Section36 />
      <Section37 />
      <Section38 />
      <Section39 />
      <Section40 />
      <Section41 />
    </div >
  );
}

export default App;
