import React from "react";
import uncheckIcon34 from "./uncheckIcon.png";
import checkIcon34 from "./checkIcon.png";
import arrow from "./arrow.png";
import paytm from "./paytm.png"

// import getintouch from "../../assets/svg/Getintouch-amico.svg";

import "./section34.css";
export default function Section34() {
  return (
    <div className="one-section one-section-34 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h1 className="lg42 md36 sm28 xs24 fw500 lh-4">Decide Your <span className="text-sea-green fw700">Destiny</span> Yourself</h1> </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-lg-6 col-md-6 without-list-box mt3">
            <div className="col-12 without-list">
              <h3 className="fw400 lh-4 text-white">
                <span className="without-box"> <img src={uncheckIcon34} className="img-fluid center-block uncheckIcon" alt="uncheck" />    LOSING SIDE </span>
              </h3>

              <ul className="without-checklist fw400 lh-6">
                <li>Continue using traditional methods.</li>
                <li>Keep spending on social media and google campaigns without a plan. </li>
                <li>Keep breaking your back by running after the customers. </li>
                <li>Continue paying hard-earned money to advertising agencies. </li>
                <li>Continue failing. </li>
                <li>Keep yourself out of the competition. </li>

              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 with-list-box mt3">
            <div className="col-12 with-list">
              <h3 className="lg30 md28 sm26 xs22 fw400 lh-4 text-white text-right">
                <span className="with-box">  WINNING SIDE <img src={checkIcon34} className="img-fluid center-block checkIcon" alt="check" />  </span>
              </h3>
              <ul className="with-checklist fw400 lh-6">
                <li> Use Mumara One with jaw-dropping features without any effort. </li>
                <li> Save your dollars without hiring any professionals. </li>
                <li> Collect more leads and convert them into sales. </li>
                <li> Select from the dozens of professionally designed templates. </li>
                <li>Manage your contact list and segment them according to users’ preferences. </li>
                <li> Dismiss old trends and step into the new world of wonders. </li>
                <li> Nurture your leads and increase ROI. </li>
              </ul>
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <h4 className="lh-4"> <span className="title3-out"><span className="title3"><span>Keep Standing on the Winning Side, We Are Here to Help You Out</span> </span></span> </h4>
          </div>
          <div className=" padtop120">
            <div className="col-md-12 section34-box max-w950 m-auto">
              <div className="col-md-12 section34-box-inn">
                <div className="row justify-content-around m-0">
                  <h3 className="fw700 mt-2 text-sea-green text-center">
                    <em>Start Using <span className='fw700'>Mumara One</span>, Now</em>
                  </h3>
                  <div className="col-md-12 text-center mt-4">
                    <div className="mybutton">
                      <a href="/" className="text-center fw400 text-uppercase  myimg-width"> <span className="mybutton-btn">
                        Sign Up Now
                        <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block myimg-width"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
