import React from "react";
import newsletter_amico_g1 from '../../assets/svg/Newsletter-amico-g1.svg';
import bg36 from './images/bg36.jpg';
import check2 from './images/check2.png';

import "./section36.css";
export default function Section36() {
  return (
    <div className="one-section one-section-36" style={{ backgroundImage: `url(${bg36})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <img
              src={newsletter_amico_g1} alt="Newsletter_amico_g1"
              className="img-fluid d-block mx-auto mt-2 max-w550"
            />
          </div>
          <div className="col-md-6">
            <div className="max-w550 mt-5 ">
              <h1 className="fw500 lh-4 text-black ">
                Catch Up on Some More <span className="text-sea-green fw700">Features</span>
              </h1>
              <div className="lh-6 fw400 text-blackish mt-4 text-left">
                <div className="list-overflow-green14 mb-20">
                  <img
                    src={check2} alt="check"
                    className="bulletlistgreen14 img-responsive"
                  />
                  <p>
                    <b>Order dedicated IPs</b> just available for you without sharing them with anyone.
                  </p>
                </div>
                <div className="list-overflow-green14 mb-20">
                  <img
                    src={check2} alt="check"
                    className="bulletlistgreen14 img-responsive"
                  />
                  <p>
                    Create Dedicate Pools to generate new <b>SMTP</b> bridges.
                  </p>
                </div>
                <div className="list-overflow-green14 mb-20">
                  <img
                    src={check2} alt="check"
                    className="bulletlistgreen14 img-responsive"
                  />
                  <p>
                    Don’t let anyone spoil your <b>IP reputation</b> - use it the way you want.
                  </p>
                </div>
                <div className="list-overflow-green14 mb-20">
                  <img
                    src={check2} alt="check"
                    className="bulletlistgreen14 img-responsive"
                  />
                  <p>
                    Generate an API token to connect <b>Mumara One</b> with any third-party application or your website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
    </div>
  )
}