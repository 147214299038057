import React from "react";
import bg33 from "./bg-bottom33.png";
import toppng33 from "../../assets/svg/top.svg";
import check33 from "./check.png";
import mamico from "../../assets/svg/Messaging-amico.svg";

import "./section33.css";
export default function Section33() {
  return (
    <div className="one-section one-section-33 pt-0" style={{ backgroundImage: `url(${bg33}) ,  radial-gradient(circle at center, #006faf 0, #2046a0 100%) ` }}>
      <div className="section-top" style={{ backgroundImage: `url(${toppng33})` }}></div>
      <div className="container my-pad-bottom25">

        <div className="row justify-content-center pb-5">
          <div className="col-md-6 align-self-center"> <img src={mamico} className="img-fluid max-w500 mx-auto d-block money" alt="money" /> </div>
          <div className="col-md-6 align-self-center section23-text">
            <p className="fs-42 fw500 lh-4 text-white"> Get Started with <span className="text-yellow fw700">Mumara One</span> for <span className="text-yellow fw600">Free</span>!</p>
            <p className="fs-22 fw400 lh-6 margint10 text-white">Email marketing through Mumara One is <span className="text-yellow fw600">cost-effective</span>.</p>
            <div className="lh-6 fw400 white margint10 text-left">

              <div className="list-overflow-green144 mb-20">
                <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                <p className="text-15 text-white list-paddingxx">
                  Send your <b className="text-white">Email Campaign </b> instantly.
                </p>
              </div>
              <div className="list-overflow-green144 mb-20">
                <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                <p className="text-15 text-white list-paddingxx">
                  You can start using it with a <b className="text-white">free account</b>.
                </p>
              </div>
              <div className="list-overflow-green144 mb-20">
                <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                <p className="text-15 text-white list-paddingxx">
                  Upgrade your <b className="text-white">Plan</b> when you start getting the results.
                </p>
              </div>
              <div className="list-overflow-green144 mb-20">
                <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                <p className="text-15 text-white list-paddingxx">
                  Send your <b className="text-white">Emails</b> whenever you want .
                </p>
              </div>
              <div className="list-overflow-green144 mb-20">
                <img src={check33} className="bulletlistgreen14 img-responsive" alt="check" />
                <p className="text-15 text-white list-paddingxx">
                  <b className="text-white"> Email marketing</b> is targeted and cost-effective.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
