import React from "react";
import subscriptions_pana1 from '../../assets/svg/Subscriptions-pana1.svg';
import check2 from './check2.png';
import "./section40.css";
export default function Section40() {
  return (
    <div className="one-section one-section-40">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12 col-12">
              <img
                src={subscriptions_pana1} alt="Subscriptions-pana1"
                className="img-fluid d-block mx-auto mt-3 max-w500"
              />
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="max-w550 mt-3">
                <h2 className="fw500 lh-4 text-black ">
                  Mumara One Enables You to <span className="text-sea-green fw800">View and Evaluate the Stats</span> for the Sent Broadcasts
                </h2>
                <div className="lh-6 fw400 text-black mt-4 text-left">
                  <div className="list-overflow-green14 mb-20">
                    <img
                      src={check2} alt="check"
                      className="bulletlistgreen14 img-responsive"
                    />
                    <p>
                      Get complete <b>Broadcast</b> and <b>Trigger</b> stats.
                    </p>
                  </div>
                  <div className="list-overflow-green14 mb-20">
                    <img
                      src={check2} alt="check"
                      className="bulletlistgreen14 img-responsive"
                    />
                    <p>
                      <b>Learn </b>the location where your <b>Email</b> is opened or clicked.
                    </p>
                  </div>
                  <div className="list-overflow-green14 mb-20">
                    <img
                      src={check2} alt="check"
                      className="bulletlistgreen14 img-responsive"
                    />
                    <p>
                      Evaluate which <b>ISP</b> is most promising in terms of open rate, and which one ranks low to fix the delivery issues.
                    </p>
                  </div>
                  <div className="list-overflow-green14 mb-20">
                    <img
                      src={check2} alt="check"
                      className="bulletlistgreen14 img-responsive"
                    />
                    <p>
                      Get the full stats of your email broadcasts and keep yourself updated about how many emails were successfully sent, delivered, opened, clicked, delayed, failed, or complaints.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}