import React from 'react'
import conn7 from './connection7.png';
import bg21 from './bg21.png';
import points from './points.png';
import blog from './blog.png';
import blogs from './blog1.png';
import mamico from './mamico.png';
import './section21.css';

export default function Section21() {
  return (
    <div className="one-section one-section-21 pb-0" style={{ backgroundImage: `url(${bg21})` }}>
      <div className="container">
        <div className="section21-box">
          <div className="col-md-12 col-12">
            <div className="licence-card mt-5">
              <div className="row align-center">
                <div className="col-md-8 col-sm-12 mx-auto">
                  <h3 className="lh-4 fw700 text-center text-white licence-shape">
                    We Have Not Finished Yet!
                  </h3>
                </div>
                <div className="col-md-12 col-12">
                  <h1 className="lh-4 fw500 text-center mt-2">
                    Trigger-Based <span className="text-sea-green fw700">Emailing</span>
                  </h1>
                </div>

                <div className="col-md-6 mt-5">
                  <div className="sectionblog-box">
                    <img src={blogs} className="img-fluid mx-auto d-block recap2" alt="recap" />
                    <div className="pading">
                      <p className="inner-padding lh-6 gray mb-0">  A trigger is an automatic reaction to a user’s particular action. Upon this particular action, Mumara One sends a trigger email as an automated email to inform a user of the occurrence of an event. You can apply a wide range of filters according to your desires.
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-md-6 mt-5">
                  <div className="sectionblog-box">
                    <img src={blog} className="img-fluid mx-auto d-block recap2" alt="recap" />
                    <div className="pading">
                      <p className="inner-padding lh-6 gray mb-0">  For example, if you select an event criterion “Contact is added to a List” you can select the action e.g, send a broadcast, start a drip campaign, send a notification email to the admin, etc. As soon as a user will be added to the selected email list, the trigger will be performed accordingly.
                      </p>

                    </div>
                  </div>

                </div>

                <div className="col-md-6 col-sm-6 col-12 p-0 mt-5">
                  <div className='points'>
                    <img src={points} className="img-fluid mx-auto d-block points" alt="points" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12 p-0">
                  <div className='mamico'>
                    <img src={mamico} className="img-fluid mx-auto d-block mamico mt-5" alt="mamico" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12"> <img src={conn7} className="img-fluid mx-auto d-block connection7" alt="connection" /> </div>
        </div>
      </div>
    </div>
  );
}