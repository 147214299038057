import React from 'react'
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";

import './section16.css';

export default function Section16() {
  return (
    <div className="one-section one-section-16 pt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section16-box max-w950 m-auto">
            <div className="col-md-12 section16-box-inn">
              <div className="row justify-content-around m-0">
                <h3 className="fw500 mt-2 text-sea-green text-center">
                  <em>Get Started with <span className="fw700 ">Mumara One</span></em></h3>
                <div className="col-md-12 text-center mt-4">
                  <div className="mybutton">
                    <a href="/" className="text-center fw400 text-uppercase"> <span className="mybutton-btn">
                      Sign Up Now
                      <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
