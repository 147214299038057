import React from "react";
import bg26 from './images/bg26.png';
import conversions from '../../assets/svg/landing_page.svg';
import grow_email from '../../assets/svg/grow_email_list.svg';
import customize from '../../assets/svg/customize.svg';
import brand_value from '../../assets/svg/brand_value.svg';
import distractions from '../../assets/svg/distractions_free.svg';
import seo from '../../assets/svg/improve_seo.svg';
import transformation from '../../assets/svg/transformation.svg';
import user_friendly from '../../assets/svg/user_friendly.svg';
import visitors from '../../assets/svg/visitors.svg';
import "./section26.css";
export default function Section26() {
  return (
    <div className="one-section one-section-26" style={{ backgroundImage: `url(${bg26})` }}>
      <div className="container">
      <div className="col-10 text-center mx-auto">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h3 className="lh-4 text-black box mb-5">A <b>dedicated landing page</b> available on <span className="fw700 text-black">Mumara One</span> can be the new destiny of your business if you are willing to make <b>unlimited sales</b>.</h3>
          </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={conversions} alt="landing_page"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text"><b>Landing pages</b> increase your <b>conversions</b></p>		
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={grow_email} alt="grow_email_list"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">Grow your <b>email list</b> healthy</p>			
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={customize} alt="customize"
                  className="img-fluid mx-auto d-block" 
                />
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">You can <b>customize</b> and <b>test</b> various landing pages</p>	
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={brand_value} alt="brand_value"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text"><b>Increase brand value</b> and cast a good first impression</p>	
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={distractions} alt="distractions"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text"><b>Landing pages</b> are free of <b>distractions</b></p>		
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={seo} alt="improve_seo"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">They improve your <b>SEO</b> when you get more traffic</p>		
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={transformation} alt="transformation"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">Walk together with user during the <b>transformation journey</b></p>			
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={user_friendly} alt="feature"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">Landing pages are not pushy, it makes them <b>user friendly</b></p>				
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-12 text-center section26-box">
                <img
                  src={visitors} alt="visitors"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-6 feature-text">Direct all the <b>visitors</b> to the intended product or offer</p>			
                  </div>	
                </div>	
              </div>	
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
