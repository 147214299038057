import React from "react";
import referafriend from '../../assets/svg/Referafriend-rafiki1.svg';
import connection2 from "./connection2.png";
import "./section17.css";
export default function Section17() {
    return (
    <>
        <div className="one-section one-section-17">
            <div className="container">
                <div className="row justify-content-center blk-box">
                    <div className="col-md-6">
                        <img
                            src={referafriend} alt="Mumara-One-features"
                            className="img-fluid mx-auto d-block max-w450"
                        />
                    </div>
                    <div className="col-md-6 align-self-center text">
                        <h1 className="fw600 lh-4 text-white">Trying <span className="fw800 text-yellow"> Mumara One</span> Is Super Easy, Here Is How! </h1>
                        <p className="fs-20 fw400 lh-6 text-white">We are sure that after knowing the features, and benefits, you are ready to sign up for Mumara One.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 mx-auto">
            <img
                src={connection2} alt="connection"
                className="img-fluid mx-auto d-block connection2"
            />
        </div>
    </>
    )
}