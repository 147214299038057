import React from "react";
import bg from "./bg.webp";
import greenbg32 from "./greenbg32.png";
import newsletterpana from "../../assets/svg/Newsletter-pana.svg";
import "./section32.css";
export default function Section32() {
  return (
    <div className="">
      <div className="one-section one-section-32a" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
          <h2 className="fs-h1-42 text-center text-yellow">Ensure Email Consent Under GDPR</h2>
          <div className="col-md-12">
            <p className=" text-center fs-20 max-w1100 mx-auto mt-3 text-white"><b>Mumara One</b> is easy to adapt to the <b>CAN-SPAM, CASL</b>(Canadian Anti-Spam Legislation), and <b>GDPR</b>(General Data Protection Regulation) laws.It is easy to fit with the <b>spam laws</b> where it stores the history of IP addresses, geolocations, and other additional data based on recipient engagements.</p>
          </div>
        </div>
      </div>

      <div className="one-section one-section-32" >
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-12">
              <div className="col-12 section77-box my-padding-section-top-plus-bottom" style={{ backgroundImage: `url(${greenbg32})` }}>
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-10 col-sm-12">
                    <div className="">
                      <div className="mymax-stylesbox-width">
                        <div className="box2-style-sec">
                          <div className="box2-style-box box2-text-style text-center " >
                            <h1 className="fs-h1-42"> If Opportunity Doesn’t Knock, <span className="white fw600"> Build a Door</span></h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fs-20 lh-6 fw400 text-center text-white mt-1 px-md-3">
                      Using Mumara One is just like building a door for great opportunities. Yes, believe your ears, you have heard it correctly. If you are looking for more conversions and sales, we are here to provide you with the best results. You can use these for both commercial and governmental perspectives according to your best causes.
                    </div>
                    <img src={newsletterpana} className="img-fluid d-block mx-auto mt-5" alt="newsletterpana" />
                    <div className="new-sec-margin-top-bot">
                      <div className="pt-4">
                        <div className="box-style-sec box2-text-style fs-20 text-center fw400">
                          We take all the responsibilities to cover the technical side and give you all the commands to move forward toward your success without being tech-savvy. It is as easy as you buy a car and driving it towards the destination.
                        </div>
                      </div>
                    </div>
                    <div className=" fs-18 lh-6 fw400 text-center text-white px-md-3">
                      When There Is a Lot of Potential and Ease, You Must Think  <br className="d-none d-md-block" />Twice if You Are Still Reluctant to Use It.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
