import React from "react";
import elastic_email from './images/elastic-email.jpg';
import logo_amazon from './images/logo-Amazon.jpg';
import logo_aol from './images/logo-Aol.jpg';
import logo_gmail from './images/logo-Gmail.jpg';
import logo_mailgun from './images/logo-mailgun.jpg';
import logo_mailJet from './images/logo-MailJet.jpg';
import logo_outlook from './images/logo-Outlook.jpg';
import logo_powermta from './images/logo-PowerMTA.jpg';
import logo_yahoo from './images/logo-Yahoo.jpg';
import smtp from './images/smtp.jpg';
import sendgrid from './images/SendGrid.jpg';
import sparkpost from './images/sparkpost.jpg';
// 
import "./section39.css";
export default function Section39() {
  return (
    <div className="one-section one-section-39 bg-dots">
    	<div className="container">
		 	<div className="header ">
        		<h3 className="fw500 lh-4 text-white max-w650 mx-auto text-center title10"> Integrated with Globally Recognized <span className="text">Email Relay Services</span></h3>
			</div>
				<div className="col-md-8 m-auto">
					<div className="row justify-content-center mt-5">
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={smtp} alt="smtp" className="img-blk check" />
							</div>
						</div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={sendgrid} alt="sendgrid" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={sparkpost} alt="sparkpost" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={elastic_email} alt="elastic" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_amazon} alt="amazon" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_aol} alt="aol" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_gmail} alt="gmail" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_mailgun} alt="mailgun" className="img-blk check" />
							</div>
            </div>
						<div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_mailJet} alt="mailJet" className="img-blk check" />
							</div>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_yahoo} alt="yahoo" className="img-blk check" />
							</div>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_powermta} alt="powermta" className="img-blk check" />
							</div>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
							<div className="mt-3">
                <img src={logo_outlook} alt="outlook" className="img-blk check" />
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  )
}