import React from "react";
import post_pana from '../../assets/svg/Post-pana.svg';
import blogimg from './blog3.png';
import blogimg2 from './blog4.png';
import bg29 from './bg29.png';
import bg29waves from '../../assets/svg/waves.svg';
import pixel from './led-px.png';
import pixel2 from './led-px2.png';
import newsletter_cuate from '../../assets/svg/Newsletter-cuate.svg';
import messaging_amico_1 from '../../assets/svg/Getintouch-amico2.svg';
import "./section29.css";

export default function Section29() {
  return (
    <div className="one-section-29">
      <div className="one-section one-section-29-uper" style={{ backgroundImage: `url(${bg29waves}) , linear-gradient(180deg, #cfe8e6 100%, #FFFFFF 100%) ` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h1 className="fw500 lh-4 text-black max-w800 mx-auto fs-42">High Conversion Is Not a Dream Now,<span className="text-sea-green fw800"> Try Tracking Pixels </span></h1>
              <p className="fw500 lh-6 margint10 max-w950 mx-auto text-blackish fs-20">Add HTML code to the email or a website to kick-start the tracking process through the pixels. Tracking pixels are a process to see how your users behaved with your products. See the users’ engagement by following the footsteps of the users including clicks, adding items to the cart, or completing the checkout process. Do everything in a brand new style using Mumara One.</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundImage: `url(${bg29})` }}>
        <div className="container-fluid pad60-bottom pt-0" >
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="img-styling">
                <img
                  src={pixel2} alt="pixel"
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="one-section one-section-29-bottom pt-0" >
          <div className="container">
            <div className="col-md-12 mx-auto">
              <div className="row justify-content-center">

                <div className="col-md-12">
                  <div className="row justify-content-center">
                    <div className="col-md-6 align-self-center mt-5">
                      <div className="max-w550 mx-auto text-align-left">
                        <div className="margin-r-l120">
                          <h1 className="fw400 lh-4 text-white"> <span className="title5-out"><span className="title5"><span className="title5-inn">Mumara Tracking Pixels</span></span></span> </h1>
                          <p className="fw400 lh-6 margint10 text-off-white fs-20">Pixels are a way to track the activities of your users on your website or application. This is a highly intelligent way to get the trail of the visitors and retarget them by sending emails based on some activity. </p></div>

                      </div>
                    </div>
                    <div className="col-md-6 align-self-center mt-3">
                      <div className="margin-r-l120">  <img
                        src={messaging_amico_1} alt="Pixels"
                        className="img-fluid d-block mx-auto max-w400"
                      /> </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row justify-content-center ">
                    <div className="col-md-6 align-self-center order-md-2 mt-5">
                      <div className="margin-r-l120"> <p className="fw400 lh-6 mt-3 text-off-white fs-20 text-align-left">Sometimes, a user comes to your website, and adds some items to the cart but doesn’t complete the checkout process. 7 out of 10 shopping carts remain abandoned, which means that 70% of the users do not complete the purchasing process on your online store which is too much. Would you like to leave a major chunk of the buyers untouched? </p>
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center order-md-1 mt-3">
                      <div className="margin-r-l120"><img
                        src={post_pana} alt="Pixels-post"
                        className="img-fluid d-block mx-auto mt-3 max-w550"
                      /> </div>
                    </div>

                  </div>
                </div>
                <div className="col-10 text-center mx-auto">
                  <h1 className="fw400 lh-4 text-white mt-5">We suggest you use Mumara One as a complete solution to <span className="text-yellow fw600">track visitors</span> and send them <span className="text-yellow fw600">automated emails</span>. </h1>
                </div>


                <div className="col-md-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 align-self-center  mt-5">
                      <div className="margin-r-l120"> <p className="fw400 lh-9 mt-3 text-off-white fs-20 text-align-left">A <span className="text-yellow fw700">pixel</span> is a code that you place on your <span className="text-yellow fw700">website</span> to track conversions from <span className="text-yellow fw700">Facebook ads</span>, and optimized ads based on collected data. It helps you build your <span className="text-yellow fw700">targeted audience</span> for future ads to qualified leads. </p>
                      </div></div>
                    <div className="col-md-6 align-self-center mt-3">
                      <div className="margin-r-l120"><img
                        src={newsletter_cuate} alt="Pixels-newsletter"
                        className="img-fluid d-block mx-auto mt-3 max-w400"
                      /> </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 align-self-center mt-5 order-md-2">
                      <div className="margin-r-l120">  <p className="fw400 lh-6 margint10 text-off-white fs-20 text-align-left">Mumara One pixel can be installed in a couple of ways i.e., Track events by the postback URL, or Install pixel in your website, application, or any other platform. It will help you track the occurring events, top events, and monthly activity more precisely. By tracking the activities, you can target the most interested users.</p>
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center order-md-1 mt-3">
                      <div className="margin-r-l120"> <img
                        src={pixel} alt="Pixels-messaging"
                        className="img-fluid d-block mx-auto mt-3"
                      /></div>
                    </div>

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row justify-content-center">
                    <div className="col-md-6 mt-5">
                      <div className="section29-box">
                        <img src={blogimg} className="img-fluid mx-auto d-block recap2" alt="blog-img" />
                        <div className="pading">
                          <ul className="checklist2 fw400 text-blackish lh-6 text-left">
                            <li>Track users' shopping <b>behaviors and interests</b></li>
                            <li> Track the open rate of your call to action and the behavior of the users after they go to your <b>online store</b>.</li>
                            <li>You can create <b>segments</b> based on location, devices, engagement type, etc.</li>
                            <li>These segments can be targeted differently finding the best day, or best time of the day for higher ROI.</li>
                            <li>You can track the type of devices the <b>visitors</b> are using.</li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 col-md-6 mt-5">
                      <div className="section29-box">
                        <img src={blogimg2} className="img-fluid mx-auto d-block recap2" alt="blog-img2" />
                        <div className="pading">

                          <ul className="checklist2 fw400 text-blackish lh-6 text-left">
                            <li>It informs you about the location of the users.</li>
                            <li><b>Pixels reveal</b> if the users are being brought through a paid search on Facebook, Twitter, Google, etc.</li>
                            <li>You can Embed <b>pixels</b> into websites, ads, applications, or more where required. </li>
                            <li>Pixels informs you about the IPs, ad impressions, conversions, or other web activities.</li>
                            <li>Track your <b>engagements</b> and <b>earnings</b> as never before. </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   </div>

    </div >
  );
}
